import { useState } from "react";
import HeaderBar from "../Components/HeaderBar";
import Footer from "../Components/Footer";
import SeasonsSelector from "../Components/SeasonsSelector";
import SelectHoll from "../Components/SelectHoll";
import SelectFelag from "../Components/SelectFelag";
import FullScreenLoader from "../Components/FullScreenLoader";
import Kolofon from "../Components/Kolofon";

export default function Heim() {
  const [loading, setLoading] = useState(true);

  const setLoadingState = (value) => {
    setLoading(value);
  };
  return (
    <>
      <HeaderBar />
      <SeasonsSelector setLoadingState={setLoadingState} />
      <SelectHoll setLoadingState={setLoadingState} />
      <SelectFelag setLoadingState={setLoadingState} />
      <Footer />
      <Kolofon />
      {loading && <FullScreenLoader />}
    </>
  );
}
