import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <Link to="https://www.hsf.fo/">
              <img src="/logo.png" alt="hsf" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
