import HeaderBar from "../Components/HeaderBar";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
export default function Page404() {
  return (
    <>
      <HeaderBar></HeaderBar>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center content404">
            síðan finst ikki! <Link to="/">Far til forsíðu</Link>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
