import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function ShowDivisionDropdown({
  activeDivision,
  competitionName,
  ageGroup,
  setLoadingState,
}) {
  const [groupsData, setGroupsData] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState({});
  const matchGroupContainerRef = useRef(null);
  const [containerLeft, setContainerLeft] = useState(0);
  const [maleGroupList, setMaleGroupList] = useState([]);
  const [femaleGroupList, setFemaleGroupList] = useState([]);
  const [containerWidth, setContainerWidth] = useState("100%");

  let previousWidth = 100000000; // Define previousWidth here

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;

      if (currentWidth !== previousWidth) {
        const parentRow = matchGroupContainerRef.current.closest(".row");
        if (parentRow) {
          const rowRect = parentRow.getBoundingClientRect();
          const containerRect = matchGroupContainerRef.current.getBoundingClientRect();
          const leftOffset = rowRect.left - containerRect.left;
          setContainerLeft(leftOffset);

          const rowWidth = parentRow.offsetWidth;
          setContainerWidth(`${rowWidth}px`);
        }

        // Update the previous width
        previousWidth = currentWidth;
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeDivision]);

  useEffect(() => {
    const maleCompetitions = groupsData.filter((competition) => competition.gender === "male");
    const femaleCompetitions = groupsData.filter((competition) => competition.gender === "female");

    const sortData = (data) => {
      data.sort((a, b) => a.order - b.order);
      data.forEach((item) => {
        item.teams.sort((a, b) => a.order - b.order);
      });
    };

    sortData(maleCompetitions);
    sortData(femaleCompetitions);

    setMaleGroupList(maleCompetitions);
    setFemaleGroupList(femaleCompetitions);
  }, [groupsData]);

  useEffect(() => {
    const fetchGroupsData = async () => {
      try {
        if (activeDivision) {
          setLoadingState(true);
          const response = await fetch(
            `https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/competitions/${activeDivision}/groups`
          );
          const jsonData = await response.json();
          setGroupsData(jsonData);
        }
      } catch (error) {
        console.error("Error fetching active divisions data:", error);
      } finally {
        setLoadingState(false);
      }
    };

    fetchGroupsData();
  }, [activeDivision]);

  const handleExpandClick = (groupId) => {
    setExpandedGroups((prevExpandedGroups) => ({
      ...prevExpandedGroups,
      [groupId]: !prevExpandedGroups[groupId],
    }));
  };

  return (
    <>
      {groupsData && (
        <div className="matchgroup-container-wrapper">
          <div
            className="matchgroup-container"
            style={{ left: `${containerLeft}px`, position: "relative", width: containerWidth }}
            ref={matchGroupContainerRef}
          >
            <section className={`matchGroups ${activeDivision}`}>
              {maleGroupList.length > 0 && (
                <div className="group">
                  <div className="groupheading">{ageGroup === "Vaksin" ? "Menn" : "Dreingir"}</div>
                  <section className="foldouts container">
                    <div
                      className={`wrap row ${
                        maleGroupList.length === 1 && "justify-content-center"
                      }`}
                    >
                      {maleGroupList.map((group) => (
                        <div key={group.id} id={group.id} className="foldout col-12 col-md-6">
                          <div className={`header ${expandedGroups[group.id] ? "active" : ""}`}>
                            <Link to={`/dystir-og-stigatalva/${competitionName}/${group.id}`}>
                              <h3>{group.name}</h3>
                            </Link>
                            <div className="expand" onClick={() => handleExpandClick(group.id)}>
                              {expandedGroups[group.id] ? "-" : "+"}
                            </div>
                          </div>
                          <div
                            className={`hiddencontent ${expandedGroups[group.id] ? "show" : ""}`}
                          >
                            <ul>
                              {group.teams.map((team) => (
                                <li key={team.teamId} id={team.teamId}>
                                  {team.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                </div>
              )}
              {femaleGroupList.length > 0 && (
                <div className="group">
                  <div className="groupheading">{ageGroup === "Vaksin" ? "Kvinnur" : "Gentur"}</div>
                  <section className="foldouts container">
                    <div
                      className={`wrap row ${
                        femaleGroupList.length === 1 && "justify-content-center"
                      }`}
                    >
                      {femaleGroupList.map((group) => (
                        <div key={group.id} className="foldout col-md-6 col-12">
                          <div className={`header ${expandedGroups[group.id] ? "active" : ""}`}>
                            <Link to={`/dystir-og-stigatalva/${competitionName}/${group.id}`}>
                              <h3>{group.name}</h3>
                            </Link>
                            <div className="expand" onClick={() => handleExpandClick(group.id)}>
                              {expandedGroups[group.id] ? "-" : "+"}
                            </div>
                          </div>
                          <div
                            className={`hiddencontent ${expandedGroups[group.id] ? "show" : ""}`}
                          >
                            <ul>
                              {group.teams.map((team) => (
                                <li key={team.teamId}>{team.name}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                </div>
              )}
            </section>
          </div>
        </div>
      )}
    </>
  );
}
