import React, { useState, useEffect } from "react";

export default function HollMatches({ selectedVenue, selectedDate, setLoadingState }) {
  const [matchesData, setMatchesData] = useState([]);
  const venue_schedule_api_url = `https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/venues/${selectedVenue}/schedule?from=${selectedDate}&days=100`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingState(true);
        const response = await fetch(venue_schedule_api_url);
        const data = await response.json();
        console.log(data.schedule);
        setMatchesData(data.schedule);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingState(false);
      }
    };

    fetchData();
  }, [selectedVenue, selectedDate]);

  const convertDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString("fo-FO", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  let previousDate = null;
  let inTable = false;

  // Render matches grouped by day
  return (
    <section className="venue-matches">
      <div>
        <div>
          <div>
            <table className="table">
              <tbody>
                {matchesData.map((match) => {
                  const currentDate = convertDate(match.date._seconds);
                  const shouldCreateNewTable = currentDate !== previousDate;
                  previousDate = currentDate;

                  return (
                    <>
                      {shouldCreateNewTable && (
                        <tr className="vendate">
                          <td colspan="7" className="venue-date">
                            {currentDate}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td className="left-align venue-clock">
                          {match.time}
                          <div className="justsmall">{match.group.name}</div>
                        </td>
                        <td className="left-align venue-league">
                          {match.group.name}
                          {match.matchReport ? (
                            <img
                              title="heinta dómaraseðil"
                              className="pdfimg"
                              alt=""
                              src="/pdf.png"
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="right-align match-team-name">{match.homeTeam.name}</td>
                        <td className="match-team-logo center-align venue-team-logo">
                          <img
                            src={`https://files.hsf.fo/clubs/${match.homeTeam.clubId}.webp`}
                            alt="Team 1 Logo"
                          />
                        </td>
                        <td className="dash">
                          {match.result?.home.goalsFor}-{match.result?.away.goalsFor}
                        </td>
                        <td className="match-team-logo center-align venue-team-logo">
                          <img
                            src={`https://files.hsf.fo/clubs/${match.awayTeam.clubId}.webp`}
                            alt="Team 2 Logo"
                          />
                        </td>
                        <td className="left-align match-team-name">{match.awayTeam.name}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}
