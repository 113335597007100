import React, { useState, useEffect } from "react";
import HollMatches from "./HollMatches";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function SelectHoll({ setLoadingState }) {
  const [venues, setVenues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(""); // State to store the selected venue
  const [selectedDate, setSelectedDate] = useState(null); // State to store the selected date

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        setLoadingState(true);
        const response = await fetch(
          "https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/venues"
        );
        const data = await response.json();
        // Sort venues alphabetically based on name
        const sortedVenues = data.sort((a, b) => a.name.localeCompare(b.name));
        setVenues(sortedVenues);
      } catch (error) {
        console.error("Error fetching venues:", error);
      } finally {
        setLoadingState(false);
      }
    };

    fetchVenues();
  }, []);

  const handleVenueChange = (event) => {
    setSelectedVenue(event.target.value); // Update the selected venue state
  };

  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setSelectedDate(formattedDate); // Update the selected date state
  };

  return (
    <>
      <section className="select-holl">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Finn dystir í hallum</h3>
            </div>
            <div className="col-12 col-md-6">
              <div className="head">Í Høll</div>
              <div class="relative">
                <select className="hollselct" onChange={handleVenueChange}>
                  <option key="velholl" value="">
                    Vel høll
                  </option>
                  {venues.map((venue) => (
                    <option key={venue.id} value={venue.id}>
                      {venue.name}
                    </option>
                  ))}
                </select>
                <div className="select-arrow">
                  <img src="/caret-down.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="head">Frá degi</div>
              <div class="relative">
                <DatePicker
                  className="dateselect"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy" // Danish date format
                  placeholderText="Vel dag"
                />
                <img className="calicon" src="/calendar.svg" alt="" />
              </div>
            </div>
            {selectedVenue !== "" && selectedDate && (
              <HollMatches
                selectedVenue={selectedVenue}
                selectedDate={selectedDate}
                setLoadingState={setLoadingState}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
}
