import React from "react";
import ReactDOM from "react-dom/client";
import "./custom.css";
import "./css/bootstrap.css";
import Heim from "./Pages/Heim";
import DystirOgStigatalva from "./Pages/DystirOgStigatalva";
import Page404 from "./Pages/Page404";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  { path: "/", element: <Heim />, errorElement: <Page404 /> },
  {
    path: "/dystir-og-stigatalva/:kappingSlag/:kappingId/",
    element: <DystirOgStigatalva />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
