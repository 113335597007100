import React, { useState, useEffect } from "react";

export default function PointsTable({ competitionId, setLoadingState }) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingState(true);
        const response = await fetch(
          `https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/groups/${competitionId}/standings`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const jsonData = await response.json();
        setTableData(jsonData.table);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
        setLoading(false);
      } finally {
        setLoadingState(false);
      }
    };

    fetchData();
  }, [competitionId]);

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <section className="standing-table">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <table className="table standingsTable" style={{ display: "table" }}>
                <thead>
                  <tr>
                    <th className="pd-med">Støðan</th>
                    <th className="blue center-align">
                      <span>Dystir</span>
                    </th>
                    <th className="blue center-align">
                      <span>Vunnin mál</span>
                    </th>
                    <th className="blue center-align">
                      <span>Tapt mál</span>
                    </th>
                    <th className="blue center-align">
                      <span>Munur</span>
                    </th>
                    <th className="blue center-align">
                      <span>Stig</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="standingOverview">
                  {tableData.map((row, index) => (
                    <tr key={index}>
                      <td className="bold uppercase">
                        <img
                          src={`https://files.hsf.fo/clubs/${row.clubId}.webp`}
                          className="klub-logo"
                          alt="lid"
                        />
                        <span>{row.name}</span>
                      </td>
                      <td className="center-align">{row.games}</td>
                      <td className="center-align">{row.goals}</td>
                      <td className="center-align">{row.against}</td>
                      <td className="center-align">{row.difference}</td>
                      <td className="bold center-align">{row.points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
