import React, { useState, useEffect } from "react";
import ClubMatches from "./ClubMatches";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function SelectFelag({ setLoadingState }) {
  const [clubs, setClubs] = useState([]);
  const [selectedClub, setSelectedVenue] = useState(""); // State to store the selected club
  const [selectedDate, setSelectedDate] = useState(null); // State to store the selected date

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        setLoadingState(true);
        const response = await fetch(
          "https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/clubs"
        );
        const data = await response.json();
        // Sort clubs alphabetically based on name
        const sortedClubs = data.sort((a, b) => a.name.localeCompare(b.name));
        setClubs(sortedClubs);
      } catch (error) {
        console.error("Error fetching clubs:", error);
      } finally {
        setLoadingState(false);
      }
    };

    fetchVenues();
  }, []);

  const handleClubChange = (event) => {
    setSelectedVenue(event.target.value); // Update the selected club state
  };

  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setSelectedDate(formattedDate); // Update the selected date state
  };

  return (
    <>
      <section className="select-holl">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Finn dystir hjá felagi</h3>
            </div>
            <div className="col-12 col-md-6">
              <div className="head">Hjá felagi</div>
              <div class="relative">
                <select className="hollselct" onChange={handleClubChange}>
                  <option key="velholl" value="">
                    Vel felag
                  </option>
                  {clubs.map((club) => (
                    <option key={club.id} value={club.id}>
                      {club.name}
                    </option>
                  ))}
                </select>
                <div className="select-arrow">
                  <img src="/caret-down.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="head">Frá degi</div>
              <div class="relative">
                <DatePicker
                  className="dateselect"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy" // Danish date format
                  placeholderText="Vel dag"
                />
                <img className="calicon" src="/calendar.svg" alt="" />
              </div>
            </div>
            {selectedClub !== "" && selectedDate && (
              <ClubMatches
                selectedClub={selectedClub}
                selectedDate={selectedDate}
                setLoadingState={setLoadingState}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
}
