export default function Kolofon() {
  return (
    <div className="container-fluid footerbottom">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <a className="kolofon" target="_blank" href="//www.sendistovan.fo">
              <img src="/kolofon.svg" alt="Sendistovan" />
              Ein loysn frá Sendistovuni
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
