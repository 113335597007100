import HeaderBar from "../Components/HeaderBar";
import Footer from "../Components/Footer";
import DystarYvirlit from "../Components/DystarYvirlit";
import PointsTable from "../Components/PointsTable";
import FullScreenLoader from "../Components/FullScreenLoader";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Kolofon from "../Components/Kolofon";

export default function DystirOgStigatalva() {
  const params = useParams();
  const [scheduleData, setScheduleData] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [cupStatus, setCupStatus] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(true);

  const setLoadingState = (value) => {
    setLoading(value);
  };

  //console.log(scheduleData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingState(true);
        const response = await fetch(
          `https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/groups/${params.kappingId}/schedule`
        );
        const jsonData = await response.json();
        setScheduleData(jsonData.schedule);
        setGroupName(jsonData.group.name);
        setCupStatus(jsonData.group.cup);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingState(false);
      }
    };

    fetchData();
  }, [showTable]);
  const callHideTable = () => {
    setShowTable(false);
  };

  const callShowTable = () => {
    setShowTable(true);
  };

  return (
    <>
      <HeaderBar kappingSlag={params.kappingSlag} showBack={true} groupName={groupName} />
      <section className="matchesStanding-select">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <ul>
                <li
                  className={!showTable ? "tabselect active" : "tabselect"}
                  onClick={callHideTable}
                >
                  Dystir
                </li>
                {!cupStatus && (
                  <li
                    className={showTable ? "tabselect active" : "tabselect"}
                    onClick={callShowTable}
                  >
                    Støðan
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {!showTable && <DystarYvirlit scheduleData={scheduleData} groupName={groupName} />}
      {showTable && (
        <PointsTable setLoadingState={setLoadingState} competitionId={params.kappingId} />
      )}
      <Footer />
      <Kolofon />
      {loading && <FullScreenLoader />}
    </>
  );
}
