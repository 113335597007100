import React, { useState, useEffect } from "react";
import CompetitionSelect from "./CompetitionsSelect";

export default function SeasonsSelector({ setLoadingState }) {
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState();

  // Effect to fetch data on initial load
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingState(true);
        const response = await fetch(
          "https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/seasons"
        );
        const jsonData = await response.json();
        const sortedData = jsonData.sort(
          (a, b) => parseInt(b.name.split("-")[0]) - parseInt(a.name.split("-")[0])
        );
        setSeasons(sortedData.filter((season) => season.name !== "2021-22"));
        if (sortedData.length > 0) {
          // Set the selected season to the ID of the first element
          setSelectedSeason(sortedData[0].id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingState(false);
      }
    };

    fetchInitialData();
  }, []);

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
  };

  return (
    <>
      <section className="year-select">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="custom-select">
                <select value={selectedSeason} onChange={handleSeasonChange}>
                  {seasons.map((season) => (
                    <option key={season.id} value={season.id}>
                      {season.name}
                    </option>
                  ))}
                </select>
                <div className="select-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#090A0A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {selectedSeason && (
        <CompetitionSelect seasonId={selectedSeason} setLoadingState={setLoadingState} />
      )}
    </>
  );
}
