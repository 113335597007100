import { useState, useEffect, useRef } from "react";
import SelectDivision from "./SelectDivision";

export default function CompetitionSelect({ seasonId, setLoadingState }) {
  const [typesData, setTypesData] = useState([]);
  const [competitionName, setCompetitionName] = useState("Landskappingin");
  const [activeCompetitionTypeId, setActiveCompetitionTypeId] = useState(null);
  const listRef = useRef(null);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  useEffect(() => {
    const fetchTypesData = async () => {
      try {
        setLoadingState(true);
        const response = await fetch(
          `https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/seasons/${seasonId}/Types`
        );
        const jsonData = await response.json();

        // Filter items with object.name of either 'Landskappingin' or 'Steypakapping'
        const filteredData = jsonData.filter(
          (type) =>
            type.name === "Landskappingin" ||
            type.name === "Steypakappingin" ||
            type.name === "Lionsbikarið"
        );

        // Sort the filtered items so that 'Landskappingin' comes first, 'Steypakappingin' comes second, and 'Lionsbikarið' comes last
        const sortedData = filteredData.sort((a, b) => {
          if (a.name === "Landskappingin") return -1;
          if (b.name === "Landskappingin") return 1;
          if (a.name === "Steypakappingin" && b.name !== "Landskappingin") return -1;
          if (b.name === "Steypakappingin" && a.name !== "Landskappingin") return 1;
          if (a.name === "Lionsbikarið") return 1;
          if (b.name === "Lionsbikarið") return -1;
          return 0;
        });

        setTypesData(sortedData);

        // Set the first item as active initially
        if (sortedData.length > 0) {
          setActiveCompetitionTypeId(sortedData[0].id);
        }
      } catch (error) {
        console.error("Error fetching competition types data:", error);
      } finally {
        setLoadingState(false);
      }
    };

    if (seasonId) {
      fetchTypesData();
    }
  }, [seasonId]);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    handleSwipe();
    setTouchStartX(null);
    setTouchEndX(null);
  };

  const handleSwipe = () => {
    const deltaX = touchEndX - touchStartX;
    // Adjust the threshold as needed for swipe sensitivity
    const threshold = 50;

    if (Math.abs(deltaX) > threshold) {
      if (deltaX > 0) {
        // Swipe right, handle accordingly
        // For example: swipe to show previous items
        handlePreviousItem();
      } else {
        // Swipe left, handle accordingly
        // For example: swipe to show next items
        handleNextItem();
      }
    }
  };

  const handlePreviousItem = () => {
    // Implement logic to show previous item
  };

  const handleNextItem = () => {
    // Implement logic to show next item
  };

  const handleItemClick = (itemId) => {
    setActiveCompetitionTypeId(itemId.id);
    setCompetitionName(itemId.name);
  };

  return (
    <>
      <section className="competition-select">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <ul
                ref={listRef}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                style={{ overflowX: "auto", whiteSpace: "nowrap" }}
              >
                {typesData.map((type) => (
                  <li
                    value={type.id}
                    key={type.id}
                    className={type.id === activeCompetitionTypeId ? "active" : ""}
                    onClick={() => handleItemClick(type)}
                    style={{ display: "inline-block" }}
                  >
                    {type.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <SelectDivision
        selectedCompetitionData={activeCompetitionTypeId}
        competitionName={competitionName}
        setLoadingState={setLoadingState}
      />
    </>
  );
}
