import { useState, useEffect } from "react";
import ShowDivisionDropdown from "./ShowDivisionDropdown";

export default function SelectDivision({
  selectedCompetitionData,
  competitionName,
  setLoadingState,
}) {
  const [divisionData, setDivisionData] = useState([]);
  const [activeDivision, setActiveDivision] = useState(null);
  const [ageGroup, setAgeGroup] = useState("");

  //console.log(activeDivision);
  useEffect(() => {
    const fetchDivisionsData = async () => {
      try {
        setLoadingState(true);
        const response = await fetch(
          `https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/types/${selectedCompetitionData}/competitions`
        );
        const jsonData = await response.json();

        // Sort the data by the 'order' property
        jsonData.sort((a, b) => a.order - b.order);

        setDivisionData(jsonData);
        setActiveDivision(null);
      } catch (error) {
        console.error("Error fetching divisions data:", error);
      } finally {
        setLoadingState(false);
      }
    };

    if (selectedCompetitionData) {
      fetchDivisionsData();
    }
  }, [selectedCompetitionData]);

  const handleClick = (id, value, age) => {
    const clickedElement = document.getElementById(id);

    // If the clicked element already has the 'active' class
    if (clickedElement.classList.contains("active")) {
      // Remove 'active' and 'active-division-tab' classes from clicked element
      clickedElement.classList.remove("active");
      //  clickedElement.classList.remove("active-division-tab");
      // Set the active division state to null
      setActiveDivision(null);
    } else {
      // Remove 'active-division-tab' class from all divisions
      document.querySelectorAll(".name").forEach((element) => {
        element.classList.remove("active");
      });
      // Add 'active' and 'active-division-tab' classes to clicked element
      clickedElement.classList.add("active");
      //  clickedElement.classList.add("active-division-tab");
      // Set the active division state to the clicked division ID
      setActiveDivision(id);
      setAgeGroup(age);
    }
  };

  return (
    <>
      <section className="select-division">
        <div className="container">
          <div className="row justify-content-center division-grid-wrapper">
            {divisionData.map((division, index) => (
              <div
                className={`division colnumber-${(index % 4) + 1} col-md-3 col-6`}
                data-grouprel={division.id}
                key={division.id}
              >
                <div
                  id={division.id}
                  onClick={() => handleClick(division.id, division.name, division.ageGroup.name)}
                  value={division.id}
                  className="name"
                  key={division.id}
                >
                  {division.name}
                </div>

                {activeDivision === division.id && (
                  <ShowDivisionDropdown
                    activeDivision={activeDivision}
                    competitionName={competitionName}
                    ageGroup={ageGroup}
                    setLoadingState={setLoadingState}
                  ></ShowDivisionDropdown>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
